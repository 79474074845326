import store from '@/store';

export const getSelected = () => {
    const { selected } = store.state.chooser!;
    if(selected.length)
        return selected;

    store.commit('setToast', { text: 'יש לבחור שאלות כדי להמשיך' });
}

export const isLegend = (id: string | number) => typeof id === 'string' && id.startsWith('l')